import {LocalStore} from 'Utils/localStorageUtils';
import {AUTHENTICATED_USER_STATUS, LOCAL_STORAGE} from 'Utils/constants';
import {AuthenticatedUserParams} from './AuthGuard.interface';
import {ACCESS_CONTROL, ROLE} from '../../Utils/roles';
import {UserDetails} from 'Utils/commonTypes';

export function getAuthParams(
  userParams: UserDetails
): AuthenticatedUserParams {
  let {
    userRole = undefined,
    status = undefined,
    user_role_id = undefined
  } = userParams as AuthenticatedUserParams;
  if (!status) {
    status = AUTHENTICATED_USER_STATUS.INACTIVE;
  }
  if (!userRole) {
    userRole = ROLE.Public.name;
  }
  // eslint-disable-next-line
  if (!user_role_id) {
    // eslint-disable-next-line
    user_role_id = ROLE.Public.id;
  }

  return {userRole, status, user_role_id};
}

export function getUserControls(userRole: string) {
  return ACCESS_CONTROL[userRole];
}
