import React from 'react';

import {Icon} from '@steera/react-components';
import {Block} from 'Components/Block';
import {Text} from 'Components/Text';
import {EMAIL_VERIFICATION_SUCCESS_MESSAGE} from './EmailVerificationSuccess.constants';
import {EmailVerificationSuccessProps} from './EmailVerificationSuccess.interface';

import './EmailVerificationSuccess.scss';

/**
 * Email Verification Success Screen when email verification is successful
 * @param props none
 * @returns JSX.Element
 */
export function EmailVerificationSuccess(props: EmailVerificationSuccessProps) {
  return (
    <Block className='ve-ves'>
      <span className='ve-ves_icon'>
        <Icon title='check circle' />
      </span>
      <Text
        text={EMAIL_VERIFICATION_SUCCESS_MESSAGE}
        className='ve-ves_title'
      />
    </Block>
  );
}
