import * as React from 'react';
import {SessionExpiredProps} from './SessionExpired.interface';
import {useNavigate} from 'react-router-dom';
import {ROUTE} from 'Routes.contants';
import {SessionExpired as C} from '@steera/react-components';
import {LocalStore} from 'Utils/localStorageUtils';
import cn from './SessionExpired.module.scss';

export function SessionExpired(props: SessionExpiredProps): JSX.Element {
  const navigate = useNavigate();
  const {Wrap, Image, GoToLogin} = C;
  const onClick = () => navigate(ROUTE.HOME);
  LocalStore.clearStore();

  return (
    <Wrap className={cn.sessionexpired}>
      <Image />
      <GoToLogin className={cn.btn} onClick={onClick} />
    </Wrap>
  );
}
