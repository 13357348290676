export class Logger {
  public static info(...args: any[]) {
    // eslint-disable-next-line
    console.log(...args);
  }
  public static error(...args: any[]) {
    // eslint-disable-next-line
    console.error(...args);
  }
}
