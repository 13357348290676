import {createAction, createAsyncThunk, createReducer} from '@reduxjs/toolkit';
import {updateTreatmentPlanRequestBody} from 'API/TreatmentPlans';
import {TaskStateExtended} from 'PatientModule/Screens/PatientTreatment/TreatmentPlans/TreatmentPlans.interface';
import {TreatmentOptionType} from 'ProductModule/ProstateCancer/Screens/ProstateCancer/ProstateCancer.interface';
import {RootState} from './store';
import {UserDetails} from 'Utils/commonTypes';
import {getUserInfo} from 'BaseModule/API/Redirect';

export const setUnreadMessagesCount = createAction<number>(
  'messages/set-total-unread-messages'
);

export const setInProgressTreatmentTask = createAction<TaskStateExtended>(
  'treatmentplantask/set-inprogress-task'
);
export const setInProgressTreatmentTemplateHeader = createAction<
  updateTreatmentPlanRequestBody['treatment_plan']
>('treatmentplan/set-inprogress-treatmentplanheader');

export const setTreatmentPlanId = createAction<string>(
  'treatmentplan/set-treatmentplan-id'
);

export const setTreatmentOption = createAction<TreatmentOptionType>(
  'prostatecancer/set-treatmentoption'
);

export const setUserDetails = createAction<UserDetails>('user/set-user');

export const refreshUser = createAsyncThunk(
  'users/refresh',
  async (_, thunkAPI) => {
    try {
      const response = await getUserInfo();
      if (response) {
        return response;
      }
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

export const clearUser = createAction('store/clear-user');

const initialState: {
  totalUnreadMessages: number;
  task: any;
  templateHeader: any;
  templateId: string;
  treatmentOption: TreatmentOptionType | undefined;
  user: UserDetails | undefined;
} = {
  totalUnreadMessages: 0,
  /**
   * Selected Survey Task Description
   */
  task: {},
  /**
   * Selected Task's Template Header
   */
  templateHeader: {},
  /**
   * Treatment Plan Template ID (Medicaluser Module)
   */
  templateId: '',
  /**
   * Treatment Option (Prostate Cancer)
   */
  treatmentOption: undefined,
  /**
   * User Details
   */
  user: undefined
};
export const appReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setUnreadMessagesCount, (state, {payload}) => {
      state.totalUnreadMessages = payload;
    })
    .addCase(setInProgressTreatmentTask, (state, {payload}) => {
      state.task = payload;
    })
    .addCase(setInProgressTreatmentTemplateHeader, (state, {payload}) => {
      state.templateHeader = payload;
    })
    .addCase(setTreatmentPlanId, (state, {payload}) => {
      state.templateId = payload;
    })
    .addCase(setTreatmentOption, (state, {payload}) => {
      state.treatmentOption = payload;
    })
    .addCase(setUserDetails, (state, {payload}) => {
      state.user = payload;
    })
    .addCase(refreshUser.fulfilled, (state, {payload}) => {
      state.user = payload as any;
    })
    .addCase(clearUser, (state) => {
      state.user = undefined;
    });
});

export const getTotalUnreadMessages = (state: RootState) =>
  state.app.totalUnreadMessages;

export const getTreatmentPlanTask = (state: RootState) => state.app.task;
export const getTreatmentPlanHeader = (state: RootState) =>
  state.app.templateHeader;
export const getTreatmentPlanTemplateId = (state: RootState) =>
  state.app.templateId;

export const getTreatmentOption = (
  state: RootState
): TreatmentOptionType | undefined => state.app.treatmentOption;

export const getUserDetails = (state: RootState): UserDetails | undefined =>
  state.app.user;
