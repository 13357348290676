import * as React from 'react';
import {ROUTE} from 'Routes.contants';
import {LogOutProps} from './LogOut.interface';
import {useNavigate} from 'react-router-dom';
import {LogOut as L} from '@steera/react-components';
import {LocalStore} from 'Utils/localStorageUtils';

import cn from './LogOut.module.scss';
import {useDispatch} from 'react-redux';
import {clearUser} from 'Store/reducer';

export function LogOut(props: LogOutProps): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onClick = () => navigate(ROUTE.HOME);
  const {Wrap, GoToLogin, Image} = L;

  React.useLayoutEffect(() => {
    LocalStore.clearStore();
    dispatch(clearUser());
  }, []);

  return (
    <Wrap className={cn.logout}>
      <Image />
      <GoToLogin className={cn.btn} onClick={onClick}>
        Go Back to Home
      </GoToLogin>
    </Wrap>
  );
}
