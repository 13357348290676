export const ROUTE = {
  BASE_MODULE: '/*',
  HOME: '/',
  // Platform admin
  PLATFORM_ADMIN_MODULE: '/platformadmin/*',
  PLATFORM_ADMIN: '/platformadmin',
  // Medical user
  MEDICAL_USER_MODULE: '/medicaluser/*',
  MEDICAL_USER: '/medicaluser',
  // Patient user
  PATIENT_MODULE: '/patient/*',
  PATIENT: '/patient',
  // Admin user
  ADMIN_MODULE: '/admin/*',
  ADMIN: '/admin',
  // Other Routes
  VERIFY_EMAIL: '/verifyemail',
  NOACCESS: '/noaccess',
  SESSIONEXPIRED: '/sessionexpired',
  NO_DATA: '/nodata',
  PAGE_NOT_FOUND: '/pagenotfound',
  SOMETHING_WENT_WRONG: '/somethingwentwrong',
  LOG_OUT: '/logout'
};
