import React, {Children} from 'react';
import {BlockProps} from './Block.interface';
import './Block.scss';

export function Block(props: BlockProps) {
  const getClassName = () => props.className ?? 'block';
  return (
    <div className={getClassName()} onClick={props.onClick} title={props.title}>
      {props.children}
    </div>
  );
}
