import {ROUTE} from 'Routes.contants';

export const ROLE = {
  Specialist: {
    name: 'Specialist',
    id: '1'
  },
  Nurse: {
    name: 'Nurse',
    id: '2'
  },
  GP: {
    name: 'GP',
    id: '3'
  },
  Carer: {
    name: 'Carer',
    id: '4'
  },
  Registrar: {
    name: 'Registrar',
    id: '5'
  },
  Admin: {
    name: 'Admin',
    id: '6'
  },
  Doctor: {
    name: 'Doctor',
    id: '7'
  },
  Patient: {
    name: 'Patient',
    id: '8'
  },
  PlatformAdmin: {
    name: 'PlatformAdmin',
    id: '9'
  },
  AlliedHealthPractitioner : {
    name: 'Allied Health Professional',
    id: '10'
  },
  Public: {
    name: 'Public',
    id: ''
  }
};

export const ACCESS_CONTROL = {
  [ROLE.Admin.name]: {
    PAGES: [ROUTE.ADMIN_MODULE],
    CONTROLS: []
  },
  [ROLE.Specialist.name]: {
    PAGES: [ROUTE.MEDICAL_USER_MODULE],
    CONTROLS: []
  },
  [ROLE.GP.name]: {
    PAGES: [ROUTE.MEDICAL_USER_MODULE],
    CONTROLS: []
  },
  [ROLE.Nurse.name]: {
    PAGES: [ROUTE.MEDICAL_USER_MODULE],
    CONTROLS: []
  },
  [ROLE.AlliedHealthPractitioner.name]: {
    PAGES: [ROUTE.MEDICAL_USER_MODULE],
    CONTROLS: []
  },
  [ROLE.Patient.name]: {
    PAGES: [ROUTE.PATIENT_MODULE],
    CONTROLS: []
  },
  [ROLE.PlatformAdmin.name]: {
    PAGES: [ROUTE.PLATFORM_ADMIN_MODULE]
  },
  [ROLE.Public.name]: {
    PAGES: [ROUTE.BASE_MODULE],
    CONSTROLS: []
  }
};
