import {HttpRequest, isAbortError} from 'Http';
import {Logger} from 'Utils/Logger';

export async function verifyEmail(data: {patientId: any}) {
  const api: any = new HttpRequest({
    route: '/verifyEmail'
  });
  try {
    const response = await api.post('', data);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error('Failed to send vefification email');
    }
  } catch (err: any) {
    if (isAbortError(err)) {
      Logger.error(err);
    } else {
      throw err;
    }
  }
}

export async function validateEmail(data: {
  patientId: string | number;
  verificationCode: string | number;
}) {
  const api: any = new HttpRequest({
    route: '/validateEmail'
  });
  try {
    const response = await api.post('', data);
    return response;
  } catch (err: any) {
    if (isAbortError(err)) {
      Logger.error(err);
    }
    throw err;
  }
}
