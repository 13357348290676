import {HttpRequest, isAbortError} from 'Http';
import {Logger} from 'Utils/Logger';
import {UserDetails} from 'Utils/commonTypes';

export async function getCredentials(token: string, userRole: string) {
  try {
    const api: any = new HttpRequest({
      route: '/refreshToken'
    });
    const response = await api.post('', {refreshToken: token, userRole});
    if (response?.data?.data) {
      const {AccessToken, IdToken, TokenType, user} = response.data.data;
      return {
        AccessToken,
        IdToken,
        TokenType,
        user
      };
    } else {
      throw new Error('Failed to get credentials');
    }
  } catch (error: any) {
    Logger.error(error);
    if (!isAbortError(error)) {
      return;
    }
    throw error;
  }
}

export async function getUserInfo(): Promise<UserDetails | undefined> {
  const api: any = new HttpRequest({
    route: '/v1/getUserDetails'
  });
  try {
    const response = await api.get();
    if (response.data.data) {
      return response.data.data;
    }
  } catch (error: any) {
    if (isAbortError(error)) {
      Logger.info(error);
    } else {
      throw error;
    }
  }
}
