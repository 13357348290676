import React from 'react';
import {TextProps} from './Text.interface';

import './Text.scss';

/**
 * Text: p tag based placeholder component
 * @param props - text, className
 * @returns JSX.Element
 */
export function Text(props: TextProps) {
  return <p className={props.className}>{props.text}</p>;
}
