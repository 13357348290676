import * as React from 'react';
import {useNavigate} from 'react-router-dom';

import {PageNotFoundProps} from './PageNotFoundProps.interface';
import {ResourceNotFound as C} from '@steera/react-components';

import cn from './PageNotFound.module.scss';

export function PageNotFound(props: PageNotFoundProps): JSX.Element {
  const navigate = useNavigate();

  const {GoToHome, Image, Wrap} = C;

  const onClick = () => navigate(-2);

  return (
    <Wrap className={cn.pagenotfound}>
      <Image />
      <GoToHome className={cn.btn} onClick={onClick}>
        Go Back
      </GoToHome>
    </Wrap>
  );
}
