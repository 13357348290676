import {HttpRequest, isAbortError} from 'Http';
import {Logger} from 'Utils/Logger';

export async function fetchSurveyById(templateId: string) {
  const api: any = new HttpRequest({
    route: '/v1/surveyTemplate'
  });
  try {
    const response = await api.get(`/fetch/${templateId}`);
    if (response?.data?.data) {
      return {
        name: response.data.data.survey_name,
        template: response.data.data.Details
      };
    }
  } catch (error: any) {
    if (isAbortError(error)) {
      Logger.error(error);
    }
  }
}

export async function fetchSurveyList(params: {
  page: number;
  searchText: string;
  size: number;
}): Promise<
  | {
      surveys: {
        surveyName: string;
        updatedAt: string;
        id: string;
      }[];
      totalCount: number;
    }
  | undefined
> {
  const api: any = new HttpRequest({
    route: '/v1/surveyTemplate'
  });
  try {
    const response = await api.get(
      `/search?from=${params.page}&searchText=${params.searchText}&size=${params.size}`
    );
    if (response?.data?.data) {
      return {
        surveys: response.data.data.surveyTemplates.map((s: any) => ({
          surveyName: s.survey_name,
          updatedAt: s.updatedAt,
          id: s.id
        })),
        totalCount: response.data.data.totalCount
      };
    }
  } catch (error: any) {
    if (isAbortError(error)) {
      Logger.error(error);
    }
  }
}
