import React from 'react';
import {Provider} from 'react-redux';
import {store} from './Store/store';

interface ReduxProviderProps {
  /**
   * Components that needs to access redux state
   */
  children: JSX.Element;
}

/**
 * ReduxProvider
 *  - Wrapper that connect Redux to the Application
 */
export function ReduxProvider(props: ReduxProviderProps) {
  return <Provider store={store}>{props.children}</Provider>;
}
