import {AsyncSelectOption as Option} from '@steera/react-components';
import {
  getPresignedURL,
  PreSignedURLResponseType,
  uploadToS3Bucket
} from 'API/Attachments';
import {fetchSurveyList} from 'API/Survey';
import {
  getBloodTestTypes,
  getLocationList,
  getTaskEventTypes,
  getUserRoles
} from 'API/TreatmentPlanTask';
import {TreatmentOptionType} from 'ProductModule/ProstateCancer/Screens/ProstateCancer/ProstateCancer.interface';
import {Logger} from './Logger';
import {PathologyType} from 'MedicalUserModule/Screens/TreatmentPlans/TreatmentPlans.interface';

export function getFromENV(key: string): string {
  return process.env[key] ?? '';
}

const throttles: {[key: string]: any} = {};

export function getThrottle(key: string, interval = 500) {
  return (callback: () => void) => {
    if (throttles.hasOwnProperty(key)) {
      clearTimeout(throttles[key]);
    }
    throttles[key] = setTimeout(callback, interval);
  };
}

export async function fetchResponsibility(): Promise<Option[]> {
  try {
    const response = await getUserRoles();
    return response.map((u) => ({id: u.id, label: u.name, value: u.name}));
  } catch (error) {
    return [
      {
        id: '',
        label: '',
        value: ''
      }
    ];
  }
}

export async function fetchEventTypes(): Promise<Option[]> {
  try {
    const response = await getTaskEventTypes();
    return response.map((u) => ({id: u.id, label: u.name, value: u.name}));
  } catch (error) {
    return [
      {
        id: '',
        label: '',
        value: ''
      }
    ];
  }
}

export async function fetchBloodTestTypes(): Promise<PathologyType[]> {
  try {
    const response = await getBloodTestTypes();
    return response.map((u: any) => ({
      id: u.id,
      description: u.description,
      name: u.name
    }));
  } catch (error) {
    return [
      {
        id: '',
        description: '',
        name: ''
      }
    ];
  }
}

export async function fetchLocation(): Promise<Option[]> {
  try {
    const response = await getLocationList();
    return response.map((u) => ({id: u.id, label: u.name, value: u.name}));
  } catch (error) {
    return [{id: '', label: '', value: ''}];
  }
}

export async function fetchSurvey(criteria: string): Promise<Option[]> {
  try {
    const response = await fetchSurveyList({
      page: 1,
      searchText: criteria,
      size: 10
    });
    if (response) {
      return response.surveys.map((s) => {
        return {
          id: s.id,
          label: s.surveyName,
          value: s.id
        };
      });
    } else {
      throw new Error('No Survey forms');
    }
  } catch (error) {
    return [{id: '', label: '', value: ''}];
  }
}
export function getDateFromNoOfDays(date: number) {
  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + date);
  return currentDate.toISOString().split('T')[0];
}
export function getDateFromNoOfDaysForScheduleTreat(
  days: number,
  baseDate: string
) {
  let newDate = new Date(baseDate);
  newDate.setDate(newDate.getDate() + days);
  return newDate.toISOString()?.split('T')[0]; // returning in 'YYYY-MM-DD' format
}
export function getDateFromNoOfDaysForSchedule(days: number, baseDate: Date) {
  let newDate = new Date(baseDate);
  newDate.setDate(newDate.getDate() + days);
  return newDate.toISOString()?.split('T')[0]; // returning in 'YYYY-MM-DD' format
}

export function getNumberOfDaysFromDate(end: string, baseDate: string): number {
  const date1 = new Date(baseDate);
  const date2 = new Date(end);

  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = date2.getTime() - date1.getTime();
  const diffInDays = Math.round(diffInTime / oneDay);

  return diffInDays;
}

export function getOptionFromTreatmentOptions(
  treatmentOptions: TreatmentOptionType[]
): Option[] {
  return treatmentOptions.map((t) => ({
    id: t.diagnosis_treatment_option_id,
    label: t.name,
    value: t.code
  }));
}

export function getLocaleDate(date: string) {
  return new Intl.DateTimeFormat('en-GB').format(new Date(date));
}

/**
 * Get Date String in format YYYY-MM-DD from \
 * ISO format datetime string
 * @param date
 * @returns
 */
export function getDateFromISO(date: string) {
  return date.split('T')[0];
}
export function checkDateValidity(date: string) {
  let value =
    new Date(new Date().toISOString()).valueOf() - new Date(date).valueOf();
  return value > 0;
}

export function generateUID() {
  return new Date().getTime().toString(36);
}

export function convertToMultipartFormData(
  body: PreSignedURLResponseType,
  file: any
) {
  let data = new FormData();
  Object.entries(body.fields).forEach((i) => {
    data.append(i[0], i[1]);
  });
  data.append('file', file);
  return data;
}

export async function uploadAttachment(file: File) {
  try {
    if (typeof file !== 'string') {
      const response = await getPresignedURL((file as File).name || 'file');
      const formData = convertToMultipartFormData(response, file);
      const res = await uploadToS3Bucket(response.url, formData);
      if (res?.status === 204) {
        return {
          filename: response.fields.key,
          bucket: response.fields.bucket
        };
      }
    } else {
      return undefined;
    }
  } catch (error) {
    Logger.error(error);
  }
}

export function encodeURL(url: string): string {
  return encodeURIComponent(url)
    .replace(/\-/g, '%2D')
    .replace(/\_/g, '%5F')
    .replace(/\./g, '%2E')
    .replace(/\!/g, '%21')
    .replace(/\~/g, '%7E')
    .replace(/\*/g, '%2A')
    .replace(/\'/g, '%27')
    .replace(/\(/g, '%28')
    .replace(/\)/g, '%29');
}

export function decodeURL(url: string): string {
  return decodeURIComponent(
    url
      .replace(/\\%2D/g, '-')
      .replace(/\\%5F/g, '_')
      .replace(/\\%2E/g, '.')
      .replace(/\\%21/g, '!')
      .replace(/\\%7E/g, '~')
      .replace(/\\%2A/g, '*')
      .replace(/\\%27/g, "'")
      .replace(/\\%28/g, '(')
      .replace(/\\%29/g, ')')
  );
}

const SPECIAL_REGEX = /[`~<>;':"\/\[\]\|{}()=_+-]/;
const STRONG_PASSWORD_REGEX =
  /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;
/**
 * Checks if Text is Valid Text that contains no Special Characters
 */
export function isValidText(text: string) {
  return !SPECIAL_REGEX.test(text);
}
export function isStrongPassword(password: string) {
  return STRONG_PASSWORD_REGEX.test(password);
}
