import React from 'react';
import {useNavigate} from 'react-router-dom';

import {NoAccess as C} from '@steera/react-components';
import {NoAccessProps as Props} from './NoAccess.interface';

import cn from './NoAccess.module.scss';

export function NoAccess(props: Props) {
  const navigate = useNavigate();

  const {Image, GoToLogin, Wrap} = C;

  const onClick = () => navigate(-2);

  return (
    <Wrap className={cn.noaccess}>
      <Image />
      <GoToLogin className={cn.btn} onClick={onClick}>
        Go Back
      </GoToLogin>
    </Wrap>
  );
}
