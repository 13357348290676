import {TemplateNodeType} from '@steera/form-manager/dist/cjs/Comp/Comp.interface';
import {ROUTE} from 'Routes.contants';
import {LocalStore} from 'Utils/localStorageUtils';
import {LOCAL_STORAGE, ABORT_MESSAGE} from '../Utils/constants';

export function isAbortError(error: Error): boolean {
  return error.message === ABORT_MESSAGE;
}

export function getAuthToken(): string {
  return `${LocalStore.getItem(LOCAL_STORAGE.TYPE)} ${LocalStore.getItem(
    LOCAL_STORAGE.ID_TOKEN
  )}`;
}

export function sessionExpired() {
  /*eslint-disable-next-line */
  location.replace(ROUTE.SESSIONEXPIRED);
  LocalStore.clearStore();
}

export function getTemplateID() {
  const templateId = LocalStore.getItem(LOCAL_STORAGE.TEMPLATE_ID);
  return templateId;
}

export function getCurrentTemplate() {
  const selectedTemplate = LocalStore.getItem(
    LOCAL_STORAGE.CURRENT_SELECTED_TEMPLATE
  );
  return selectedTemplate ?? false;
}

export function getAssessmentID() {
  const assessmentId = LocalStore.getItem(LOCAL_STORAGE.ASSESSMENT_ID);
  return assessmentId ?? false;
}

export function getFormTemplate():
  | {name: string; template: TemplateNodeType[]}
  | undefined {
  const template = LocalStore.getItem(LOCAL_STORAGE.FORM_TEMPLATE);
  return (
    (template as {name: string; template: TemplateNodeType[]}) ?? undefined
  );
}

export function getAssessmentDisableFlag() {
  const flagValue = LocalStore.getItem(LOCAL_STORAGE.ASSESSMENT_DISABLE_FLAG);
  if (flagValue === '') {
    return false;
  } else {
    return String(flagValue).toLowerCase() === 'true';
  }
}

/**
 * @returns true if there is a Template ID
 */
export function isTemplateID(): Boolean {
  const templateId = getTemplateID();
  if (templateId !== 'false') return true;
  else return false;
}

/**
 *
 * @returns True if current template is set
 */
export function isCurrentTemplateActive(): Boolean {
  const template = getCurrentTemplate();
  if (template !== 'false') return true;
  else return false;
}
