/*eslint-disable */
import {
  searchSurvey,
  SearchSurveyResponseType
} from 'AdminModule/API/ManageSurvey';
import {HttpRequest, isAbortError} from 'Http';
import {Logger} from 'Utils/Logger';

export async function fetchTreatmentPlanTemplateTasks(
  diagnosis_treatment_options_id: string | number
) {
  const api: any = new HttpRequest({
    route: '/v1/treatmentTemplateTask'
  });
  try {
    const response = await api.get(
      `/fetch?diagnosis_treatment_options_id=${diagnosis_treatment_options_id}`
    );
    return response.data.data.data;
  } catch (error: any) {
    if (isAbortError(error)) {
      Logger.error(error);
    }
    throw error;
  }
}

export async function getUserRoles(): Promise<
  {
    id: string;
    name: string;
    description: string;
  }[]
> {
  const api: any = new HttpRequest({
    route: '/v1/task/responsibility/fetch'
  });
  try {
    const response = await api.get();
    return response.data.data.data;
  } catch (err: any) {
    if (isAbortError(err)) {
      Logger.error(err);
    }
    throw err;
  }
}

export async function getTaskEventTypes(): Promise<
  {
    id: string;
    name: string;
    description: string;
  }[]
> {
  const api: any = new HttpRequest({
    route: '/treatment_plan/getTaskEventTypes'
  });
  try {
    const response = await api.get();
    return response.data.data.data;
  } catch (err: any) {
    if (isAbortError(err)) {
      Logger.error(err);
    }
    throw err;
  }
}

export async function getBloodTestTypes(): Promise<
  {
    id: string;
    name: string;
    description: string;
  }[]
> {
  const api: any = new HttpRequest({
    route: '/treatment_plan/getTaskBloodTestTypes'
  });
  try {
    const response = await api.get();
    return response.data.data.data;
  } catch (err: any) {
    if (isAbortError(err)) {
      Logger.error(err);
    }
    throw err;
  }
}

export async function getLocationList(): Promise<
  {
    id: string;
    name: string;
    description: string;
  }[]
> {
  const api: any = new HttpRequest({
    route: '/v1/task/locationsList'
  });
  try {
    const response = await api.get();
    return response.data.data.data;
  } catch (err: any) {
    if (isAbortError(err)) {
      Logger.error(err);
    }
    throw err;
  }
}

// export async function getSurveyList(criteria: string): Promise<
//   | {
//       list: SearchSurveyResponseType[];
//       total: number;
//     }
//   | undefined
// > {
//   try {
//     const response = await searchSurvey({
//       criteria,
//       page: 1,
//       size: 10
//     });
//     return response;
//   } catch (error) {
//     Logger.error(error);
//   }
// }
