import * as React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import {FullPageLoader} from 'Components/FullPageLoader';
import {NoAccess} from './Routes/NoAccess';
import {NoData} from './Routes/NoData';
import {PageNotFound} from './Routes/PageNotFound';
import {SessionExpired} from './Routes/SessionExpired';
import {SomethingWentWrong} from './Routes/SomethingWentWrong';
import {LogOut} from './Routes/LogOut';
import {AuthGuard} from 'Components/AuthGuard';
import {EmailVerification} from 'Components/EmailVerification';

const BaseModule = React.lazy(() => import('./BaseModule'));
const AdminModule = React.lazy(() => import('./AdminModule'));
const MedicalUserModule = React.lazy(() => import('./MedicalUserModule'));
const PlatformAdminModule = React.lazy(() => import('./PlatformAdminModule'));
const PatientModule = React.lazy(() => import('./PatientModule'));

export default function Bootstrap() {
  return (
    <BrowserRouter>
      <React.Suspense fallback={<FullPageLoader />}>
        <Routes>
          <Route path={'/*'} element={<BaseModule />} />
          <Route
            path={'/admin/*'}
            element={<AuthGuard path={'/admin/*'} element={<AdminModule />} />}
          />
          <Route
            path={'/medicaluser/*'}
            element={
              <AuthGuard
                path={'/medicaluser/*'}
                element={<MedicalUserModule />}
              />
            }
          />
          <Route
            path={'/platformadmin/*'}
            element={
              <AuthGuard
                path={'/platformadmin/*'}
                element={<PlatformAdminModule />}
              />
            }
          />
          <Route
            path={'/patient/*'}
            element={
              <AuthGuard path={'/patient/*'} element={<PatientModule />} />
            }
          />
          <Route path='/noaccess' element={<NoAccess />} />
          <Route path='/nodata' element={<NoData />} />
          <Route path='/pagenotfound' element={<PageNotFound />} />
          <Route path='/sessionexpired' element={<SessionExpired />} />
          <Route path='/verify' element={<EmailVerification />} />
          <Route path='/somethingwentwrong' element={<SomethingWentWrong />} />
          <Route path='/logout' element={<LogOut />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
}
