export const LOCAL_STORAGE = {
  FORM_TEMPLATE: 'FORM_TEMPLATE',
  USER: 'USER',
  SESSION: 'SESSION',
  AUTH_TOKEN: 'AUTH_TOKEN',
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  ID_TOKEN: 'ID_TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  TYPE: 'type',
  TEMPLATE_ID: 'TEMPLATE_ID',
  CREATED_BY_ID: 'CREATED_BY_ID',
  ASSESSMENT_DISABLE_FLAG: 'ASSESSMENT_DISABLE_FLAG',
  CURRENT_SELECTED_TEMPLATE: 'CURRENT_SELECTED_TEMPLATE',
  ASSESSMENT_ID: 'ASSESSMENT_ID'
};

export const ENV = {
  DOMAIN: 'REACT_APP_DOMAIN',
  NODE_ENV: 'NODE_ENV',
  BASE_URL: 'REACT_APP_API_BASE_URL',
  STAGE_URI: 'REACT_APP_API_STAGE_URI',
  COUNTRY_CODES: 'REACT_APP_COUNTRY_CODES',
  PRODUCTION: 'PRODUCTION',
  ENVIRONMENT: 'REACT_APP_ENVIRONMENT'
};

export const ABORT_MESSAGE = 'canceled';

export const HEADER = {
  AUTHORIZATION: 'Authorization'
};

export const AUTHENTICATED_USER_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
};
