import React from 'react';

import {Icon} from '@steera/react-components';
import {Block} from 'Components/Block';
import {Text} from 'Components/Text';
import {EMAIL_VERIFICATION_FAILURE_MESSAGE} from './EmailVerificationFailure.constants';
import {EmailVerificationFailureProps} from './EmailVerificationFailure.interface';

import './EmailVerificationFailure.scss';

/**
 * Email Verification Failed Screen when email verification is a failure
 * @param props none
 * @returns JSX.Element
 */
export function EmailVerificationFailure(props: EmailVerificationFailureProps) {
  return (
    <Block className='ve-vef'>
      <span className='ve-vef_icon'>
        <Icon title='not allowed' />
      </span>
      <Text
        text={EMAIL_VERIFICATION_FAILURE_MESSAGE}
        className='ve-vef_title'
      />
    </Block>
  );
}
