import axios from 'axios';
import {HttpRequest, isAbortError} from 'Http';
import {Logger} from 'Utils/Logger';

export interface PreSignedURLResponseType {
  url: string;
  fields: {
    'key': string;
    'bucket': string;
    'X-Amz-Algorithm': string;
    'X-Amz-Credential': string;
    'X-Amz-Date': string;
    'Policy': string;
    'X-Amz-Signature': string;
  };
}
export async function getPresignedURL(
  filename: string
): Promise<PreSignedURLResponseType> {
  const api: any = new HttpRequest({
    route: '/Document',
    noAbort: true
  });
  try {
    const response = await api.post('/generatePresignedURL', {
      filename
    });
    return response?.data?.data?.data;
  } catch (error: any) {
    if (isAbortError(error)) {
      Logger.error(error);
    }
    throw error;
  }
}

export async function uploadToS3Bucket(url: string, data: FormData) {
  const api: any = new HttpRequest({
    route: url
  });
  try {
    const response = await axios.request({
      method: 'post',
      url,
      data,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response;
  } catch (error) {
    Logger.error(error);
  }
}

export async function fetchAttachmentUsingKey(attachmentKey: string) {
  const api: any = new HttpRequest({
    route: '/fetch'
  });
  try {
    const response = await api.post('/attachment', {
      key: attachmentKey
    });
    return response?.data?.data;
  } catch (error: any) {
    if (isAbortError(error)) {
      Logger.error(error);
    } else {
      throw error;
    }
  }
}
