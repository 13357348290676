import {configureStore, Store, MiddlewareArray} from '@reduxjs/toolkit';
import {CurriedGetDefaultMiddleware} from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import logger from 'redux-logger';

import {ENV} from '../Utils/constants';
import {getFromENV} from '../Utils/helpers';

import {appReducer} from './reducer';

const reducer = {
  app: appReducer
};

const getMiddleware = (
  getDefaultMiddleware: CurriedGetDefaultMiddleware<any>
): MiddlewareArray<any> => getDefaultMiddleware().concat(logger);

function getStore(env: string): Store {
  if (env === ENV.PRODUCTION) {
    return configureStore({
      reducer
    });
  } else {
    return configureStore({
      reducer,
      middleware: getMiddleware
    });
  }
}

export const store = getStore(getFromENV(ENV.NODE_ENV));

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
