import * as React from 'react';
import cn from './SomethingWentWrong.module.scss';
import {SomethingWentWrongProps} from './SomethingWentWrong.interface';
import {useNavigate} from 'react-router-dom';
import {SomethingWentWrong as C} from '@steera/react-components';
import {ROUTE} from 'Routes.contants';

export function SomethingWentWrong(
  props: SomethingWentWrongProps
): JSX.Element {
  const {Wrap, GoToHome, Image, Title} = C;
  const navigate = useNavigate();
  const onClick = () => navigate(ROUTE.HOME);

  return (
    <Wrap className={cn.somethingwentwrong}>
      <Image />
      <Title />
      <GoToHome className={cn.btn} onClick={onClick} />
    </Wrap>
  );
}
