type Key = string;
type ValueString = string;
type Value = string | object | number | boolean;
type SetStoreParams = {[key: string]: string | object};

export class LocalStore {
  private static makeValue(value: Value): ValueString {
    return JSON.stringify({value});
  }

  private static getValue(value: ValueString | null): Value {
    if (value) {
      const parsedValue = JSON.parse(value);
      return parsedValue.value;
    } else {
      return '';
    }
  }

  public static setItem(key: Key, value: Value): void {
    localStorage.setItem(key, LocalStore.makeValue(value));
  }

  public static getItem(key: string): Value {
    return LocalStore.getValue(localStorage.getItem(key));
  }

  public static clearStore() {
    localStorage.clear();
  }

  public static setStore(data: SetStoreParams) {
    for (let key of Object.keys(data)) {
      LocalStore.setItem(key, data[key] as Value);
    }
  }
}
