import React from 'react';
import {Navigate} from 'react-router-dom';
import {ROUTE} from 'Routes.contants';
import {
  AuthenticatedUserParams,
  AuthGuardProps as Props
} from './AuthGuard.interface';
import {getAuthParams, getUserControls} from './AuthGuard.utils';
import {AUTHENTICATED_USER_STATUS} from 'Utils/constants';
import {refreshUser} from 'Store/reducer';
import {useDispatch} from 'react-redux';
import {FullPageLoader} from 'Components/FullPageLoader';
import {useUser} from 'Hooks/useUser';
import {Logger} from 'Utils/Logger';

/**
 * AuthGuard
 *  - Guard component to control access for particular user onto a page
 */
export function AuthGuard(props: Props): JSX.Element {
  const [authParams, setAuthParams] = React.useState<AuthenticatedUserParams>();
  const [pages, setPages] = React.useState<string[]>();
  const user = useUser();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!user) {
      dispatch(refreshUser());
    } else {
      let authParams = getAuthParams(user);
      setAuthParams(authParams);
      if (authParams.userRole) {
        setPages(getUserControls(authParams.userRole).PAGES);
      }
    }
  }, [user]);

  if (
    authParams?.status &&
    authParams.status === AUTHENTICATED_USER_STATUS.INACTIVE
  ) {
    return <Navigate to={ROUTE.NOACCESS} replace={true} />;
  }

  if (pages) {
    if (pages.includes(props.path)) {
      return props.element;
    } else {
      return <Navigate to={ROUTE.PAGE_NOT_FOUND} replace={true} />;
    }
  } else {
    return <FullPageLoader />;
  }
}
