import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {ENV, LOCAL_STORAGE, HEADER} from '../Utils/constants';
import {getFromENV} from 'Utils/helpers';
import {LocalStore} from 'Utils/localStorageUtils';
import {getAuthToken, isAbortError, sessionExpired} from 'Http/Http.utils';
import {Logger} from 'Utils/Logger';

const BASE_URL = getFromENV(ENV.BASE_URL);
const STAGE_URI = getFromENV(ENV.STAGE_URI);

interface HttpRequestInterface {
  baseURL?: string;
  route: string;
  stagingURI?: string;
  withCredentials?: boolean;
  noAbort?: boolean;
}

const pendingRequests: any = {};

export class HttpRequest {
  baseURL: string = '';
  stagingURI: string = '';
  abortController: AbortController;
  route: string;
  instance: any;
  noAbort: boolean;
  withCredentials: boolean;

  constructor({
    baseURL = BASE_URL,
    route = '',
    stagingURI = STAGE_URI,
    withCredentials = false,
    noAbort = false
  }: HttpRequestInterface) {
    this.route = route;
    this.baseURL = baseURL;
    this.stagingURI = stagingURI;
    this.withCredentials = withCredentials;
    this.abortController = new AbortController();
    this.instance = this.getInstance();
    this.noAbort = noAbort;
    this.addRequestInterceptor();
    this.addResponseInterceptor();
    return this.instance;
  }

  getInstance() {
    return axios.create({
      baseURL: `${this.baseURL}${this.stagingURI}${this.route}`,
      signal: this.abortController.signal,
      withCredentials: this.withCredentials
    });
  }

  abortRequest(abortController: AbortController) {
    abortController.abort();
  }

  addToPendingRequests(config: AxiosRequestConfig) {
    let requestKey = makeRequestKey(config);
    if (pendingRequests.hasOwnProperty(requestKey) && !this.noAbort) {
      this.abortRequest(pendingRequests[requestKey]);
    }
    pendingRequests[requestKey] = this.abortController;
  }

  removeFromPendingRequests(config: any) {
    let requestKey = makeRequestKey(config);
    if (pendingRequests.hasOwnProperty(requestKey)) {
      delete pendingRequests[requestKey];
    }
  }

  attachAuthorizationHeader(config: any) {
    const token = LocalStore.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
    if (token) {
      config.headers[HEADER.AUTHORIZATION] = getAuthToken();
    }
  }

  addRequestInterceptor() {
    createAuthRefreshInterceptor(this.instance, setNewAccessToken, {
      statusCodes: [401],
      retryInstance: this.getInstance()
    });
    this.instance.interceptors.request.use((config: AxiosRequestConfig) => {
      this.addToPendingRequests(config);
      this.attachAuthorizationHeader(config);
      return config;
    });
  }

  addResponseInterceptor() {
    this.instance.interceptors.response.use(
      (res: AxiosResponse) => {
        this.removeFromPendingRequests(res);
        return res;
      },
      (err: any) => {
        this.removeFromPendingRequests(err);
        return Promise.reject(err);
      }
    );
  }
}

function makeRequestKey(config: AxiosRequestConfig): string {
  return `${config.method}:${config.baseURL}${config.url}`;
}

async function setNewAccessToken(error: any) {
  try {
    const refreshToken = LocalStore.getItem(LOCAL_STORAGE.REFRESH_TOKEN);
    
    const userRole = LocalStore.getItem(LOCAL_STORAGE.USER);
    const api: any = new HttpRequest({
      route: '/refreshToken',
      stagingURI: STAGE_URI,
      baseURL: BASE_URL
    });
    const response = await api.post('', {refreshToken, userRole});
    LocalStore.setItem(
      LOCAL_STORAGE.ACCESS_TOKEN,
      response.data.data.AccessToken
    );
    LocalStore.setItem(LOCAL_STORAGE.ID_TOKEN, response.data.data.IdToken);
    error.response.config.headers[HEADER.AUTHORIZATION] = getAuthToken();
    return Promise.resolve(error);
  } catch (error: any) {
    Logger.error(error);
    if (!isAbortError(error)) {
      sessionExpired();
    }
    return Promise.reject(error);
  }
}
