import * as React from 'react';
import cn from './NoData.module.scss';
import {NoDataProps} from './NoData.interface';
import {NoData as C} from '@steera/react-components';

export function NoData(props: NoDataProps): JSX.Element {
  const {Wrap, Image, Message} = C;
  return (
    <Wrap className={cn.nodata}>
      <Image />
      <Message />
    </Wrap>
  );
}
