import * as React from 'react';
import {FullPageLoaderProps} from './FullPageLoader.interface';
import {Image} from '@steera/react-components';
import Logo from '../../Images/SteeraLogo.png';
import cn from './FullPageLoader.module.scss';

export function FullPageLoader(props: FullPageLoaderProps): JSX.Element {
  return (
    <div className={cn.loader}>
      <Image src={Logo} className={cn.loader_image} />
    </div>
  );
}
