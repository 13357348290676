import {Block} from 'Components/Block';
import {FullPageLoader} from 'Components/FullPageLoader';
import React from 'react';
import {
  EmailVerificationProps,
  StatusType
} from './EmailVerification.interface';
import {EmailVerificationFailure} from './EmailVerificationFailure';
import {EmailVerificationSuccess} from './EmailVerificationSuccess';

import {useSearchParams} from 'react-router-dom';
import {PATIENT_ID, VERIFICATION_CODE} from './EmailVerification.constants';
import {validateEmail} from 'API/VerifyEmail';
import {SomethingWentWrong} from 'Routes/SomethingWentWrong';
import './EmailVerification.scss';

/**
 * Email Verification Screen to validate email verification,\
 * designed to handle redirection from email verification link, \
 * that is sent to the patients email
 * @param props
 * @returns
 */
export function EmailVerification(props: EmailVerificationProps) {
  const [searchParams] = useSearchParams();
  const verificationCode = searchParams.get(VERIFICATION_CODE);
  const patientId = searchParams.get(PATIENT_ID);
  const [status, setStatus] = React.useState<StatusType>('pending');

  async function validate() {
    if (verificationCode && patientId) {
      try {
        const response = await validateEmail({
          patientId,
          verificationCode
        });
        if (response) {
          setStatus('success');
        }
      } catch (err: any) {
        if (err.message !== 'canceled') {
          setStatus('failed');
        }
      }
    } else {
      setStatus('error');
    }
  }

  React.useEffect(() => {
    validate();
  }, []);

  const renderStatus = () => {
    switch (status) {
      case 'success':
        return <EmailVerificationSuccess />;
      case 'failed':
        return <EmailVerificationFailure />;
      case 'pending':
        return <FullPageLoader />;
      case 'error':
        return <SomethingWentWrong />;
    }
  };
  return (
    <Block className='ve'>
      <>{renderStatus()}</>
    </Block>
  );
}
